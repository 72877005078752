$font-family-sans-serif: 'Noto Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg: #000;
$body-color: #333;
$headings-color: #333;
$headings-font-weight: bold;


$primary: #D90505;

$link-color: $primary;
$link-hover-color: $primary;

$navbar-padding-y: 6px;
$navbar-nav-link-padding-x: 1rem;

$nav-tabs-link-active-bg: #000;

$navbar-light-color:                #000;
$navbar-light-hover-color:          #fff;
$navbar-light-active-color:         #fff;
$navbar-light-disabled-color:       rgba(#fff, .3);

$navbar-link-hover-bg: #000;
$navbar-link-active-bg: #000;

$navbar-border-width: 5px;

$hamburger-color: #000;

$story-headings-color: #000;

$border: 1px solid #FFDE00;

@import "../../fonts/noto-sans";
@import "../../magazine";

.col.content {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-style: italic;
  }
}

.hr {
  border-top: $border;
}

.navbar {
  @include media-breakpoint-up(md) {
    border-top: $navbar-border-width solid $primary;
  }
  border-bottom: $navbar-border-width solid #FFDE00;

  .nav-link {
    color: white;
    font-weight: $font-weight-bold;
    transform: skewX(-15deg);
    text-transform: uppercase;

    @include hover-focus {
      background-color: $nav-tabs-link-active-bg;
    }
  }
}

@include media-breakpoint-up(sm) {
  .navbar .pur-mgmt ul {
    $navbar-padding-y-and-border: $navbar-padding-y + $navbar-border-width;
    padding-top: $navbar-padding-y-and-border;

    li {
      border-top: none !important;
      margin-top: $navbar-border-width;

      a {
        background-color: #cfcfcf;
      }
    }
  }
}

.navbar.navbar-expand  {
  .nav-item {
    @include nav-item-separator(#fff, $navbar-link-font-size);
  }
}

.navbar.navbar-expand-md {
  .navbar-collapse.collapsing,
  .navbar-collapse.collapse.show {
    border-top: 1px solid #ddd;
  }
}

.sidebar .card {
  .card-header, h5 {
    font-size: 20px;
    font-style: italic;
    text-transform: uppercase;
  }
}

.sidebar .card.filled {
  background-color: #333;
  color: #fff;

  .card-header {
    color: #fff;
  }
}

.section-head .more {
  border: 1px solid $primary;
  color: $primary;
  font-style: italic;
}

.section-caption {
  color: $story-headings-color;
  font-size: 20px;
  font-weight: $font-weight-bold;
  font-style: italic;
  text-transform: uppercase;
}

.teaser-title {
  font-weight: $font-weight-bold;
}

.article-head p {
  color: #777;
}

.article-story h3, .article-story h4 {
  color: #3c3c3c;
}

header  {
  background-color: #fff;

  border-top: $navbar-border-width solid $primary;
  @include media-breakpoint-up(md) {
    border-top: 0 none;
  }
}

.navbar.navbar-expand .nav-item+.nav-item::after {
  display: none;
}

.teaser-list > li {
  border-top: $border;
}
